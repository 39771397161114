@import "fonts.css";
/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* hide shoelace (sl-*) components until the js kicks in */
:not(:defined) {
  visibility: hidden;
}

:root {
  --sl-color-primary-50: rgb(237 252 254);
  --sl-color-primary-100: rgb(193 246 253);
  --sl-color-primary-200: rgb(138 237 250);
  --sl-color-primary-300: rgb(99 224 240);
  --sl-color-primary-400: rgb(89 201 216);
  --sl-color-primary-500: rgb(77 173 186);
  --sl-color-primary-600: rgb(63 143 153);
  --sl-color-primary-700: rgb(52 117 126);
  --sl-color-primary-800: rgb(43 97 104);
  --sl-color-primary-900: rgb(31 69 74);
  --sl-color-primary-950: rgb(19 42 45);
  --sl-color-neutral-0: theme('colors.black');
}

.bg-grainy-texture {
  background-size: 20%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' opacity='20%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")
}

mark {
  @apply bg-cyan-800/40 rounded text-inherit;
}

.ul-dash {
  list-style-type: "-";
}

.custom-border-dashed {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='0' rx='12' width='100%' height='100%' fill='none' stroke='%23808080' stroke-opacity='0.2' stroke-width='8' stroke-dasharray='12,6' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E");
  border-radius: 12px;
  overflow: hidden;
}

/* form spam prevention */
.do-not-fill {
  @apply hidden;
}

/* remove soon, to be built into Tailwind */
.text-balance {
  text-wrap: balance;
}