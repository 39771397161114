@font-face {
  font-family: 'Exo2';
  src: 
    url("/fonts/Exo2-Italic-VariableFont_wght.ttf")
    format("truetype-variations");
    font-style: italic;
    font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: 
    url("/fonts/Exo2-VariableFont_wght.ttf")
    format("truetype-variations");
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: 
    url("/fonts/JetBrainsMono[wght].ttf")
    format("truetype-variations");
  font-display: swap;
}